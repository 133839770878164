// ---------------------------------------------------------
// COMMONS
// ---------------------------------------------------------
#page{
    // overflow-x: hidden;
}

.center{
    text-align: center;
}

.overflow-h{
    overflow: hidden;
}

@media (min-width: 1200px){
    .container {
        max-width: 1240px;
        &.custom-padding{
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}
.flash-notice {
    position: fixed;
    width: 50%;
    top: calc(50% - 3rem);
    left: 25%;
    padding: 3rem;
    border-radius: 6px;
    border: 1px solid rgba(0,0,0,.2);
    text-align: center;
    font-size: 1rem;
    &.success{
        background-color: #D2F0EF;
        color: $base;
    }
}
.flex-bottom{
    display: flex;
    align-items: flex-end;
}
.flex-center{
    display: flex;
    align-items: center;
}

.block-section-p5{
    position: relative;
    padding: 2rem 0 1rem 0;

    @media screen and (min-width: $screen-lg ){
        padding: 3rem 0;

    }
    &.comesnext{
        padding-bottom: 1rem;
    }
}

.grey-txt{
    color: $gris-fonce;
    @media screen and (min-width: $screen-lg){
        @include font-size(16);
}
    span{
        color: $default;
        @include font-size(16);
    }
}

.bg-light-blue{
    background-color: #F4F5F8;
}

.bg-dark-blue{
    background-color: rgba(#12396E, .45);
}

.bg-gradient{
    position: relative;
    // Add height of :before content, direction of gradient, and colors
    @include linear-gradient(277px, #E9F6FC, transparent);
}

.bg-cloud{
    position: relative;
    &:after{
        content: '';
        display: block;
        width: 140%;
        position: absolute;
        top: 150px;
        left: 11%;
        height: 905px;
        z-index: -5;
        @include bg-img('cloud-gradient.png', center, contain, no-repeat);
        @media screen and (min-width: $screen-lg ){
            width: 80%;
            top: 340px;
            left: initial;
            right: 1%;
        }
        @media screen and (min-width: $screen-mac ){
            width: 80%;
            top: 300px;
            left: initial;
            right: -2%;
        }
    }
}

.figure-rounded{
    border-radius: 8px;
    overflow-x: hidden;
}

.figure-logo{
    width: 50%;
    @media screen and (min-width: $screen-lg ){
        width: 25%;
    }
}

.citation{
    @extend .row;
    margin: 2rem 0 0 0;
    padding: 2rem;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    h2{
        color: $bleu-fonce;
        margin-top: .25rem;
        @include font-size(28);
    }
    .title-citation{
        @include font-size(18);
    }
    .date-tag{
        margin-top: 1rem;
    }

}

.map-mid-height{
    height: 70vh;
    >{
        height: 100%;
    }
}

.map-full-height{
    height: 100vh;
    position: sticky;
    top: 0;
    >{
        height: 100%;
    }
}

.block-main-contact{
    #map_wrapper{
        margin: 1rem 0;
    }
}

.block-programme{
    padding: 2rem;
    p{
        color: $base;
    }
    @media screen and (max-width: $screen-lg ){
        padding: 1rem;
    }
}


:root {
    --br-blobby: 50%;
    --br-blobby-before: 50%;
    --br-blobby-after: 50%;
}


@media screen and (min-width: $screen-lg ){
    .box {
        top: 350px;
        animation: rotate 5s linear infinite;
        @include linear-gradient(277px, to bottom, #EDF4FE, #ECFBFD);
        border-radius: var(--br-blobby);
        position: absolute;
        height: 705px;
        width: 80%;
        max-width: 80%;
        transition: 6s linear;
        right: -200px;
        &.box-right{
            top: 500px;
            right: -350px;
            z-index: -2;
        }
        &:after {
            content: '';
            @include linear-gradient(277px, #EDF4FE, #ECFBFD);
            border-radius: var(--br-blobby-after);
            position: absolute;
            opacity: .8;
            top: -2%;
            left: -2%;
            height: 104%;
            width: 104%;
            transition: all 3s linear ;
        }
        &:before {
            content: '';
            @include linear-gradient(277px, to bottom, #E9F6FC, #ECFBFD);
            border-radius: var(--br-blobby-before);
            position: absolute;
            opacity: .5;
            top: -4%;
            left: 10%;
            height: 108%;
            width: 90%;
            transition: 5s linear;
        }
    }
}

.box-shadow-col{
    @include blue-box-shadow();
    background-color: white;
    padding: 1rem;
    color: $bleu-fonce;
}

#liste-actualites{
    margin-top: 2.2rem;
}

// ---------------------------------------------------------
// V1
// ---------------------------------------------------------

.block-after-body{
    padding: 0 1rem;
    margin-top: 1rem;
    @media screen and (min-width: $screen-lg ){
        padding: 2rem 4rem;
        display: flex;
        flex-direction: row-reverse;
        .block-aside{
            width: 30%;
        }
        .block-main{
            width: 70%;
        }
    }
    @media screen and (min-width: $screen-xl ){
        padding: 2rem 6rem;
    }
    @media screen and (min-width: 1440px ){
        padding: 2rem 8rem;
    }
    @media screen and (min-width: 1600px ){
        padding: 2rem 15rem;
    }
    @media screen and (min-width: 1880px ){
        padding: 2rem 20rem;
        .block-aside{
            width: 25%;
        }
        .block-main{
            width: 75%;
        }
    }
}

.margint-05{
     margin-top: .5rem;
}


// ---------------------------------------------------------
// RH
// ---------------------------------------------------------

    .card-vertical{
      display: flex;
      flex-direction: column;
      box-shadow: 0px 10px 45px rgba(87, 177, 216, .15);
      text-decoration: none;
      background-color: white;
      margin-bottom: 1.5rem;
      @media screen and (min-width: $screen-lg){
        flex-direction: row;
        padding: 1rem;
      }
      .card-title{
        color: $base !important;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.2;
        margin-bottom: 1rem;
        @media screen and (min-width: $screen-lg){
          font-size: 1.7rem;
        }
      }
      .card-body{
        @media screen and (min-width: $screen-lg){
          width: 80%;
        }
      }
      .card-footer{
        text-align: right;
        background-color: transparent;
        border: none;
        @media screen and (min-width: $screen-lg){
          width: 25%;
        }
        @media screen and (max-width: $screen-lg){
          display: flex;
        }
      }
      .btn-underline{
        font-weight: bold;
        text-decoration: underline;
        color: $vert-deau;
        margin-top: .85rem;
        display: inline-block;
      }
      
    } // .card-vertical

    .tag{
      border-radius: 4px;
      display: inline-block;
      margin: 5px;
      padding: 10px 20px;
      &:first-child{
        margin: 5px 5px 5px 0;
      }
    }
    .tag-green-blue{
      @extend .tag;
      color: $base;
      background-color: rgba($borderblue, .35);
    }

    .content-rh{
        .header-rh{
            display: flex;
            align-items: center;
            border-bottom: 1px solid $gris-clair;
            padding-bottom: 1.5rem;
            justify-content: space-between;
            .sub-titles-with-h1 h1{
                margin-bottom: 0;
                padding-top: .5rem;
            }
        }
        h2{
            font-size: 1.5rem;
            margin-top: 2.5rem !important;
            margin-bottom: 1rem;
        }
        p{
            margin-top: 0;
        }
        .wrap-form-rh{
            background-color: rgba($borderblue, .2);
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        input, textarea{
            border: 1px solid $borderblue;
            border-radius: 50px;
            background-color: white;
            padding: .5rem 1rem;
            height: auto;
            color: $base;
            font-family: $body-font-family;
            font-size: 1rem;
        }
        textarea{
            border-radius: 10px;
        }
        label{
            font-family: $body-font-family;
            color: rgba($base, .65);
            font-size: .85rem;
        }
        ::placeholder {
          color: rgba($base, .65);
          font-size: .85rem;
        }
        input[type="file"]{
            font-size: .75rem;
            &::-webkit-file-upload-button{
                background-color: rgba($borderblue, .35);
                border: none;
                border-radius: 30px;
                padding: .5rem;
                cursor: pointer;
                font-size: .85rem;
                &:hover{
                    background-color: rgba($borderblue, .5);
                }
            }
        }
    }

    .item-content{
      h2, h3, h4, h5{
        font-weight: 500;
        color: $base;
        &:first-child{
          margin-top: 0;
        }
      }
      p{
        color: $base;
        line-height: 1.4;
        font-size: 1rem;
        font-family: $body-font-family;
      }
      a{
        text-decoration: underline;
        font-weight: 500;
      }
    }
    #form-candidature{
        margin-top: 2.5rem;
    }
    #candidature{
        h2{
            margin-top: 1rem !important;
            margin-bottom: 1.5rem;
            color: $base;
        }
    }
    #highlits-rh{
        color: $base;
        font-weight: 500;
        margin-top: 2rem;
        justify-content: space-between;
        .summary{
            line-height: 1.5;
        }
    }
    @media screen and (max-width: $screen-lg ){
        #toApply {
            margin-top: 1rem;
            margin-bottom: 0;
            width: 100%;
            text-align: center;
            display: inline-block;
        }
    }
    #modalSpontanee{
        .modal-content{
            background-color: #F6F8FA;
        }
    }
    

