// ---------------------------------------------------------
// FRONT PAGE
// ---------------------------------------------------------

.block-main-home-page{
  .col-flex{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (min-width: $screen-lg){
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    } // query
  } // .col-flex
  .lieu-op{
    span{
      display: block;
      font-weight: 600;
    }
  }
} // block-main-home-page

// For display right colors alternatives
// and schema "Autres sites"
#block-sites{
  .row{
    &:last-child{
      position: relative;
      height: 100%;
      // schema background image
      &:before{
        content:'';
        display: block;
        @include bg-img('sites-alter-schema.svg', top, contain, no-repeat);
        @media screen and (min-width: $screen-lg){
          width: 374px;
          height: 374px;
          position: absolute;
          @include centeredAbsolute();
          z-index: 2;
          top: 5%;
        }
        @media screen and (min-width: $screen-xl){
          width: 474px;
          height: 474px;
          top: calc((326px - 75%) / -1);
        }
      }
      .col-12{
        position: initial;
        @media screen and (max-width: $screen-lg){
          padding: 0;
        }
        &:first-child{
          .card-sites{
            @media screen and (min-width: $screen-lg){
              text-align: right;
              padding-right: 3rem;
            }
            &:first-child{
              .card-title{
                color: $bleu-fonce;
              }
            }
            &:last-child{
              .card-title{
                color: $violet;
              }
              .card-btn{
                @include hoverBtn($violet, tint(#733972, 20%), white, white);
              }
            }
          }
        } // first col-12
        &:last-child{
          .card-sites{
            @media screen and (min-width: $screen-lg){
              padding-right: 3rem;
            }
            &:first-child{
              .card-title{
                color: $bleu-clair;
              }
              .card-btn{
                @include hoverBtn($bleu-clair, tint(#559BCF, 20%), white, white);
              }
            }
            &:last-child{
              .card-title{
                color: $vert-clair;
              }
              .card-btn{
                @include hoverBtn($vert-clair, tint(#B1C903, 20%), #ffffff, white);
              }
            }
          }
        }
          @media screen and (min-width: $screen-lg){
            &:before{
              content: '';
              display: block;
              width: calc(100% - 30px);
              height: calc(50% - .5rem);
              background-color: $lightblue;
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              @include centeredAbsolute();
          }
        } // col after
        @media screen and (min-width: $screen-lg){
          &:after{
            content: '';
            display: block;
            width: calc(100% - 30px);
            height: calc(50% - .5rem);
            background-color: $lightblue;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
            @include centeredAbsolute();
          } // col after
        }
        .card-sites{
          @media screen and (min-width: $screen-lg){
            margin-bottom: .5rem;
          }
          &:last-child{

            @media screen and (min-width: $screen-lg){
              margin-bottom: 0;
              margin-top: .5rem;
            }
          }
        }

      } // col
    } // last child row
  } // row
}

#block-actions{
  position: relative;
  @media screen and (min-width: $screen-lg){
    margin-bottom: 6rem;
  }
  &:before{
    content: '';
    display: block;
    width: 470px;
    height: 955px;
    position: absolute;
    right: 0;
    top: -160px;
    z-index: -2;
    @include bg-img('cloud-gradient-2.png', center, contain, no-repeat);
  }
}