// ---------------------------------------------------------
// Variables
// ---------------------------------------------------------

// FONTS
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

$body-font-family: 'Roboto', sans-serif;
$font-bold: 'Roboto', sans-serif;
$title-font: 'Roboto', sans-serif;
$html-font-size: 16 !default; // px
$body-font-size: 14 !default; // px

// COLORS 
$default:         #111111;
$base: #3b5b88 !default;
$vert-clair: #b1c903 !default;
$vert-fonce:      #4c9562, !default;
$vert-deau:       #22b3b1, !default;
$bleu-fonce:      #3b5b88 !default;
$bleu-clair:      #559bcf !default;
$violet:          #733972 !default;
$rose:            #b9036c, !default;
$orange:          #f9b200, !default;
$gris-fonce:      #82827e, !default;
$gris-clair: #f0f0f0 !default;

// Colors V2
$color-dark-blue: #022c63 !default;

$borderblue: #D3DAE4;
$lightblue: #f4f5f8;

$border-radius: 2px;

// ------------------------------------
// Breakpoints
// ------------------------------------

$screen-mac: 2048px !default;
$screen-com: 1920px !default;
$screen-xxl: 1580px !default;
$screen-xl: 1200px !default;
$screen-lg: 1024px !default;
$screen-md: 992px !default;
$screen-sm: 768px !default;
$screen-xs: 576px !default;
$screen-min: 320px !default;

// COLUMNS
$global-width: 1170px !default;
$gutter-width: 1.5em !default;