// ---------------------------------------------------------
// MODALS
// ---------------------------------------------------------

.modal{
  .modal-header{
    border-bottom: 0;
  }
}

.modal-rounded{
  .modal-content{
    border-radius: 15px;
  }
}


#modal-contact{
  .modal-title{
    color: $bleu-fonce;
    font-weight: bold;
    font-size: 1rem;
  }
  label{
    color: $bleu-fonce;
    margin-top: .5rem;
  }
  .btn-submit{
    background-color: $bleu-fonce;
    color: white;
    border-radius: 4px;
    width: fit-content;
    padding: .25rem 1rem;
    &:after{
      float: right;
      content: "";
      border: 5px solid white;
      border-width: 0 2px 2px 0;
      display: block;
      padding: 4px;
      margin-top: 7px;
      margin-left: 10px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

  }
}

.block-main-terrains{
  .btn-modal{
    background-color: $bleu-fonce;
    color: white;
    font-weight: bold;
    border-radius: 0 0 20px 20px;
    width: fit-content;
    margin: 1rem auto;
    padding: 1rem 2rem;
    &:after{
      float: right;
      content: "";
      border: 5px solid white;
      border-width: 0 2px 2px 0;
      display: block;
      padding: 4px;
      margin-top: 7px;
      margin-left: 10px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    @media screen and (min-width: $screen-lg ){
      margin:2rem 0;
    }
  }
}

