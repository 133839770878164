
// Gallery transformed into slider on xs devices

@supports (scroll-snap-type: mandatory) or (-webkit-scroll-snap-type: mandatory) {
  .is-not-supported {display: none;}
}

@media screen and (max-width: $screen-lg){

  * {
    box-sizing: border-box;
    scrollbar-color: transparent transparent; /* thumb and track color */
    scrollbar-width: 0px;
  }

  *::-webkit-scrollbar {
    width: 0;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }

  * {
    -ms-overflow-style: none;
  }

  ol, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .block-gallery {
    position: relative;
    padding-top: 65%;
    perspective: 100px;
    overflow: hidden;
  }

  .block-gallery-no-padding{
    padding-top: 0;
  }

  .carousel-viewport {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow-x: scroll;
    counter-reset: item;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
  }


  .carousel__slide{
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  } // .carousel__slide

  .carousel-snapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    scroll-snap-align: center;
    overflow: hidden;
    figure{
      width: 100%;
      height: 100%;
      border-radius: 15px;
      overflow: hidden;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (hover: hover) {
    .carousel-snapper {
      animation-name: tonext, snap;
      animation-timing-function: ease;
      //animation-duration: 4s;
      //animation-iteration-count: infinite;
    }

    .carousel__slide:last-child .carousel-snapper {
      animation-name: tostart, snap;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    .carousel-snapper {
      animation-name: none;
    }
  }

  .carousel:hover .carousel-snapper,
  .carousel:focus-within .carousel-snapper {
    animation-name: none;
  }

/*  .carousel-navigation {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    .carousel-navigation-list{
      .carousel-navigation-item {
        display: inline-block;
        .carousel-navigation-button {
          display: inline-block;
          width: 1.5rem;
          height: 1.5rem;
          //background-color: #333;
          background-clip: content-box;
          border: 0.25rem solid transparent;
          border-radius: 50%;
          font-size: 0;
          transition: transform 0.1s;
        } // .carousel-navigation-button
      } // .carousel-navigation-item
    } // .carousel-navigation-list
  } // .carousel-navigation*/


// ADDING NAV
.block-section-gallery::before,
  .block-section-gallery::after,
  .carousel-prev,
  .carousel-next {
    position: absolute;
    top: calc(50% - 1rem);
    bottom: 0;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    font-size: 0;
    outline: 0;
    background-color: white;
    z-index: 10;
    display: block;
    @include blue-box-shadow;
  }

  .block-section-gallery::before,
  .carousel-prev {
    left: -1rem;
  }

  .block-section-gallery::after,
  .carousel-next {
    right: -1rem;
  }

  .block-section-gallery::before,
  .block-section-gallery::after {
    content: '';
    z-index: 1;
    background-color: #333;
    background-size: 1.5rem 1.5rem;
    background-repeat: no-repeat;
    background-position: center center;
    color: #fff;
    font-size: 2.5rem;
    line-height: 4rem;
    text-align: center;
    pointer-events: none;
  }

  /*
  .block-section-gallery::before {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E");
  }

  .block-section-gallery::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E");
  }
  */

} // screen LG max



@media screen and (min-width: $screen-lg){

  .carousel-viewport{
    position: relative;
    height: 500px;
    margin: 0 auto;
    background-color: white;
    border-radius: 15px;
    padding: 15px;
    display: grid;
    gap: 1px 1px;
    &.carousel-viewport-grid-2{
      grid-template-columns: 50% 50%;
      grid-template-rows: 50% 50%;
      grid-template-areas: "first third" "first third";
      .carousel__slide{
        .second{
          display: none;
        }
        &:last-child{
          grid-area: third;
          margin-top: 0;
          margin-bottom: 0;
        }
      } // .carousel__slide
    }
    &.carousel-viewport-grid-3{
      grid-template-columns: 65% 35%;
      grid-template-rows: 50% 50%;
      grid-template-areas: "first second" "first third";
    }
    .carousel__slide{
      display: none;
      cursor: pointer;
      width: 100%;
      // Displaying 3 first contents
      &:nth-child(1), &:nth-child(2) {
        display: block;
      }
      // first child size 50%
      &:nth-child(1){
        grid-area: first;
        width: calc(100% - 7.5px);
      }
      // first child size 50%
      &:nth-child(2){
        grid-area: second;
        margin-bottom: 7.5px;
        width: calc(100% - 7.5px);
        margin-right: 0;
        margin-left: auto;
      }
      // first child size 50%
      &:last-child{
        display: block;
        grid-area: third;
        margin-top: 7.5px;
        width: calc(100% - 7.5px);
        margin-right: 0;
        margin-left: auto;
      }
      .carousel-snapper{
        width: 100%;
        height: 100%;
        position: relative;
        figure{
          width: 100%;
          height: 100%;
          overflow: hidden;
          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    } // .carousel-slide
  }
} // Screen LG MIN


.carousel-viewport-no-grid{
  position: relative;
  height: 500px;
  margin: 0 auto;
  background-color: white;
  border-radius: 15px;
  padding: 15px;
  display: flex;
}
