.gw-style{
 .gm-style-iw{
   span{

   }
 }
}

.gm-style-iw a {
  font-size: 1rem;
  color: #b7b2b2;
  text-decoration: underline;
  margin-top: .5rem;
  &:hover{
    color: #3B5B88;
  }
}

.gm-ui-hover-effect{
  top: 5px !important;
  right: 5px !important;
}

.gm-style-iw{
  &.gm-style-iw-c{
    .gm-style-iw-d{
      div{
        div{
          display: flex;
          flex-direction: column;
          align-items: center;
          min-height: 125px;
          justify-content: center;
          min-width: 200px;
          span{
            font-size: 1rem;
            &:first-child{
              color: #022C63;
            }
            &:nth-child(2){
              color: #111111;
              font-weight: bold;
              font-size: 1.2rem;
            }
         }
        }
      }
    }
  }
}