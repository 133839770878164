// ---------------------------------------------------------
// ICONS
// ---------------------------------------------------------

.icon-square{
  &:before{
    content: "";
    display: block;
    width: 25px;
    height: 25px;
  }
}

// add marginbottom class
.icon-mb{
  &:before{
    margin-bottom: 1rem;
  }
}


.icon-quote{
  &:before{
    content: "";
    display: block;
    width: 39px;
    height: 34px;
    @include bg-img("icon-quote.svg", center, contain, no-repeat);
  }
}

.icon-facebook{
  @extend .icon-square;
  &:before{
    @include bg-img("icon-facebook.svg", center, contain, no-repeat);
    width: 20px;
    height: 20px;
  }
}

.icon-linkedin{
  @extend .icon-square;
  &:before{
    @include bg-img("icon-linkedin.svg", center, contain, no-repeat);
    width: 20px;
    height: 20px;
  }
}
.icon-instagram{
  @extend .icon-square;
  &:before{
    @include bg-img("icon-instagram.svg", center, contain, no-repeat);
    width: 26px;
    height: 26px;
    margin-left: 12px;
  }
}

.icon-enveloppe{
  &:before{
    content: "";
    display: block;
    width: 41px;
    height: 31px;
    @include bg-img("icon-enveloppe.svg", center, contain, no-repeat);
  }
}

.icon-enveloppe-white{
  &:before{
    content: "";
    display: block;
    width: 22px;
    height: 31px;
    @include bg-img("icon-envelope-white.svg", center, contain, no-repeat);
  }
}

.icon-phone{
  &:before{
    content: "";
    display: block;
    width: 22px;
    height: 31px;
    @include bg-img("icon-phone.svg", center, contain, no-repeat);
  }
}

.icon-marker{
  &:before{
    content: "";
    display: block;
    width: 20px;
    height: 31px;
    @include bg-img("icon-marker.svg", center, contain, no-repeat);
  }
}

.icon-arrow-next{
  &:before{
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    @include bg-img("icon-left-arrow-circular-purple.svg", center, contain, no-repeat);
  }
}

.icon-box, .icon-amenagement{
  &:before{
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    @include bg-img("icon-box.svg", center, contain, no-repeat);
  }
}

.icon-thermometer, .icon-reseaux-de-chaleur{
  &:before{
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    @include bg-img("icon-thermometer.svg", center, contain, no-repeat);
  }
}

.icon-lotus, .icon-spavita{
  &:before{
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    @include bg-img("icon-lotus.svg", center, contain, no-repeat);
  }
}

.icon-wind-energy, .icon-energies-renouvelables{
  &:before{
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    @include bg-img("icon-wind-energy.svg", center, contain, no-repeat);
  }
}

.icon-tower-crane, .icon-construction{
  &:before{
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    @include bg-img("icon-tower-crane.svg", center, contain, no-repeat);
  }
}

.icon-car, .icon-citiz{
  &:before{
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    @include bg-img("icon-car.svg", center, contain, no-repeat);
  }
}

.icon-car-parking, .icon-stationnement{
  &:before{
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    @include bg-img("icon-car-parking.svg", center, contain, no-repeat);
  }
}

.icon-office-building, .icon-immobilier-dentreprise{
  &:before{
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    @include bg-img("icon-office-building.svg", center, contain, no-repeat);
  }
}

.icon-key{
  &:before{
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    @include bg-img("icon-key.svg", center, contain, no-repeat);
  }
}

.icon-land{
  &:before{
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    @include bg-img("icon-land.svg", center, contain, no-repeat);
  }
}

.icon-euro{
  &:before{
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    @include bg-img("icon-euro.svg", center, contain, no-repeat);
  }
}

.icon-tramway{
  &:before{
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    @include bg-img("icon-tramway.svg", center, contain, no-repeat);
  }
}

.icon-eco-house, .icon-opah{
  &:before{
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    @include bg-img("icon-eco-house.svg", center, contain, no-repeat);
  }
}

.icon-download{
  &:before{
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    @include bg-img("icon-download.svg", center, contain, no-repeat);
  }
}