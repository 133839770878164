// ---------------------------------------------------------
// PAGE ACTUALITES
// ---------------------------------------------------------


.block-after-body.block-after-body-actualites{
    background-color: $gris-clair;
    margin-top: 0;

    @media screen and (min-width: $screen-xl ){
        margin-top: -8rem;
    }


    // Import block main CSS
    @import "page-actualites-main"; 
    
}

// SVG AND TITLES CONTENT
.container-fluid.container-fluid-actualites{
    text-align: center;
    .main-title{
        text-align: center;
        color: $bleu-fonce;
        padding: 8rem 1rem 1rem 3rem;
        position: relative;
        span{
            font-weight: bold;
        }
        &:after{
            position: absolute;
            z-index: 0;
            bottom: -6%;
            left: 40%;
            width: 15%;
            content: ''; 
            border: $bleu-fonce 4px solid;
            @media screen and (min-width: $screen-sm ){
                left: 47%;
                width: 5%;
            }
        }
    }
    .content-svg-bg{
        @media screen and (min-width: $screen-xl ){
            margin-top: -2rem;
        }
    }
    svg{
        margin-top: 3rem;
    } 
}


#toprojet{
    background: $bleu-fonce;
    padding: 2rem;
    display: block;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.15rem;
    &:hover{
        background: $vert-deau;
    }
}

.download-link{
    padding: 1rem;
    display: block;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title-download{
        font-size: 1.1rem;
        color: $base;
        font-weight: 500;
        padding: .5rem 0;
    }
    .download-line{
        display: flex;
        align-items: center;
        color: $vert-deau;
        i{
            margin-right: .5rem;
        }
    }
}