// ---------------------------------------------------------
// CARDS
// ---------------------------------------------------------

.card{
  width: 100% !important;
  border-bottom: 10px solid $bleu-fonce;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 2rem;
  -webkit-box-shadow: 1px -7px 19px 2px rgba(0,0,0,0.05);
  -moz-box-shadow: 1px -7px 19px 2px rgba(0,0,0,0.05);
  box-shadow: 1px -7px 19px 2px rgba(0,0,0,0.05);
  @media screen and (min-width: $screen-sm ){
    margin: 2rem 0;
    min-height: 95%;
  }
  .card-header{
    background-color: white;
    border-bottom: none;
    padding: 1.25rem;
    .location{
      padding: 2rem 0;
      text-align: center;
      text-transform: uppercase;
      background-color: $bleu-fonce;
      color: white;
      font-size: 1.5rem;
      font-weight: 50;
      letter-spacing: .1rem;
    }
  }
  .card-body{
    text-align: center;
    .card-subtitle{
      font-weight: 100;
      color: $bleu-fonce;
      text-transform: uppercase;
      padding-top: 2rem;
      padding: 0;
    }
    .card-title{
      margin: 1rem 0 2rem 0;
      position: relative;
      font-size: 1.8rem;
      a{
        font-weight: bold;
        color: $bleu-fonce;
      }
      &:after{
        position: absolute;
        z-index: 0;
        bottom: -60%;
        left: 40%;
        width: 20%;
        content: '';
        border: $bleu-fonce 4px solid;
        @media screen and (min-width: $screen-sm ){
          bottom: -20px;
        }
      }
    }
    p{
      text-align: center !important;
      color: $bleu-fonce;
      font-weight: 500;
      padding: 2rem 2rem 1rem 2rem;
      line-height: 1.2rem;
      @media screen and (min-width: $screen-sm ){
        font-size: 1rem;
        line-height: 1.3rem;
      }

    }

  } // card body
  .card-footer{
    background-color: white;
    border: none;
    .content-svg{
      width: 50px;
      height: 50px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      padding: 1rem;
      margin: 1rem auto;
      background-color: $bleu-fonce;
    }
  }
} // card

// card base (actualites home..)
.card-base{
  &:hover{
    @include transition;
    p, h3{
      opacity: .8;
    }
    figure{
      img{
        transform: scale(1.1);
      }
    }
  }
  @include transition;
  .card-title{
    font-size: 1.35rem;
    color: $default;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  figure{
    width: 100%;
    height: 70%;
    margin-bottom: 1rem;
    border-radius: 4px;
    overflow: hidden;

    img{
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 4px;
      @include transition;
    }
  }
} // card base (actualites home..)

// card base format vertical
.card-base-vertical{
  @extend .card-base;
  $cardHeaderWidth : 29%;
  width: 100%;
  margin-bottom: 2rem;
  @media screen and (min-width: $screen-lg ){
    display: flex;
  }
  figure{
   width: 100%;
    @media screen and (min-width: $screen-lg ){
      width: $cardHeaderWidth;
    }
  }
  .card-body{
    width: 100%;
    align-items: flex-start;
    padding: 1rem 0;
    @media screen and (min-width: $screen-lg ){
      width: calc(100% - #{$cardHeaderWidth});
      padding: 0 1rem;
    }
    .card-text{
      color: $gris-fonce;
    }
    .card-title{
      margin: 0;
      display: flex;
      flex-direction: column;
    }
  }
} // .card-base-vertical

// card with blue box shadow (page about)
.card-blue-shadow-vertical{
  $cardHeaderWidth : 50%;
  margin-bottom: 2rem;
  background-color: white;
  @include blue-box-shadow;
  padding: 1rem;
  color: $bleu-fonce;
  &:first-child{
    @media screen and (min-width: $screen-lg ){
      margin-top: 3rem;
    }
  }
  @media screen and (min-width: $screen-lg ){
    $cardHeaderWidth : 23%;
    display: flex;
  }
  figure{
    width: $cardHeaderWidth;
    margin: 1rem;
    @media screen and (min-width: $screen-lg ){
      padding: 1rem;
      margin: 0;
    }
  }
  .card-body{
    @media screen and (min-width: $screen-lg ){
      width: calc(100% - #{$cardHeaderWidth});
    }
    font-size: 1rem;
    line-height: 1.3;
  }
  i{
    margin-right: .5rem;
    display: flex;
    justify-content: flex-end;
    @media screen and (min-width: $screen-lg ){
      width: 8%;
      align-items: flex-end;
    }
    @media screen and (min-width: $screen-lg ){
      margin-bottom: .5rem;
    }
  }
} // .card-blue-shadow-vertical

// card with blue box shadow (page nos-metiers)
.card-blue-shadow-horizontal{
  $cardHeaderWidth : 50%;
  margin-bottom: 2rem;
  background-color: white;
  @include blue-box-shadow;
  padding: 1rem;
  color: $bleu-fonce;
  @media screen and (min-width: $screen-lg ){
    $cardHeaderWidth : 23%;
  }
  figure{
    width: $cardHeaderWidth;
    margin: 1rem;
    @media screen and (min-width: $screen-lg ){
      padding: 1rem;
      margin: 0;
    }
  }
  .card-body{
    font-size: 1rem;
    line-height: 1.3;
  }
} // .card-blue-shadow-vertical

.card-sites{
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: $screen-md){
    flex-wrap: wrap;
    width: 50%;
    background-color: $lightblue;
    margin: 0 15px 1rem 15px;
  }
  @media screen and (min-width: $screen-lg){
    padding: 2rem;
  }
  .card-title{
    font-weight: bold;
    margin-bottom: 1rem;
    @include font-size(18);
  }
  .card-body{
    padding: 0 0 1rem 0;
    color: $gris-fonce;
  }
  .card-footer{
    background-color: transparent;
    border: none;
    padding: initial;
  }
}

.card-pills{
  display: flex;
  align-items: center;
  border-radius: 80px;
  background-color: white;
  margin-bottom: 1rem;
  padding: 1rem;
  @include shadow;
  .number{
    @include roundedBox(70, $vert-deau);
    @include font-size(20);
    font-weight: bold;
  }
  .card-title{
    width: calc(100% - 70px);
    font-weight: 600;
    margin-bottom: .25rem;
    @include font-size(18);
    color: $bleu-fonce;
    padding: 0 1.25rem 0 1.25rem;
    line-height: 1.2;
  }
}

.card-vertical{
  $cardHeaderWidth: 100%;
  display: flex;
  margin-bottom: 1rem;
  .card-header{
    background-color: transparent;
    width: 100%;
    @media screen and (min-width: $screen-lg){
      width: 29%;
    }
    border: none;
    padding: 0;
    figure{
      width: 100%;
      min-height: 165px;
      height: 100%;
      border-radius: 4px;
      overflow: hidden;
      img{
        object-position: center;
        object-fit: cover;
        height: 100%;
      }
    }
  }
  .card-body{
    @media screen and (min-width: $screen-lg){
      width: calc(100% - #{$cardHeaderWidth});
    }
    .card-text{
      margin-bottom: 0;
      color: #82827E;
    }
  }
  .card-title{
    color: #111111;
    font-weight: bold;
    @include fluid-type(font-size, 320px, 1200px, 1rem, 1.4rem);
    display: flex;
    flex-direction: column;
    span{
      color: #3b5b88;
      font-size: 1.4rem;
      line-height: 1.2;
      margin-bottom: 0.5rem;
      &:first-child{
        color: $vert-clair;
        text-transform: uppercase;
        font-size: 1rem;
        margin-bottom: 0.25rem;
        font-weight: 400;
      } // first child
    } // span
  }
}


.content-cards-grid{
  display: flex;
  flex-wrap: wrap;
  $marginColumn : 20px;
  .card-grid{
    &:nth-child(1), &:nth-child(4), &:nth-child(5),&:nth-child(8),&:nth-child(9){
      width: 100%;
      @media screen and (min-width: $screen-lg){
        width: calc(60% - #{$marginColumn});
      }
    }
    @media screen and (min-width: $screen-lg){
      &:nth-child(odd){
        margin-right: #{$marginColumn};
      }
      &:nth-child(even){
        margin-left: #{$marginColumn};
      }
    }
    &:nth-child(2), &:nth-child(3), &:nth-child(6),&:nth-child(7),&:nth-child(10),&:nth-child(11){
      width: 100%;
      @media screen and (min-width: $screen-lg){
        width: calc(40% - #{$marginColumn});
      }
    }
  }
}

.card-agences{
  @extend .card-base;
  @include blue-box-shadow;
  color: $bleu-fonce;
  padding: 1.5rem;
  line-height: 1.2rem;
  margin: 1rem 0;
  &:first-child{
    margin-top: 0;
  }
  .name-agency{
    padding-bottom: .5rem;
    font-weight: 600;
    font-size: 1.35rem;
  }
  .title-hours{
    font-size: 1.15rem;
    font-weight: 500;
    padding: .5rem 0;
  }
  a{
    &:hover{
      text-decoration: underline;
    }
  }
  ul{
    padding: .5rem 0;
  }

  p{
    margin: 0;
  }
}
