// ---------------------------------------------------------
// Tags
// ---------------------------------------------------------

.date-tag{
  font-size: .85rem;
  font-style: italic;
  padding-left: 60px;
  position: relative;
  color: $gris-fonce;
  &:before{
    content: "";
    height: 1px;
    width : 50px;
    background-color: $gris-fonce;
    position: absolute;
    left: 0;
    top: 9px;
  }
}

.city-tag{
  background-color: $vert-clair;
  padding: .25rem 1rem;
  border-radius: 30px;
  margin-bottom: .85rem;
  display: inline-flex;
  color: white;
}
.category-tag{
  font-size: .85rem;
  font-style: italic;
  padding-left: 60px;
  position: relative;
  &:before{
    content: "";
    height: 1px;
    width : 50px;
    background-color: white;
    position: absolute;
    left: 0;
    top: 9px;
  }
}