// ---------------------------------------------------------
// Mixins
// 1. font-size
// 2. hover
// 3. linear gradient
// 4. background image
// 5. Center div on absolute
// 6. Hover button
// 7. Box shadow effet
// 8. Transition
// ---------------------------------------------------------

@mixin font-size($size) {
    // font-size: #{$size}px; //Fallback in px
    font-size: calculate-rem($size);
 }
 
 @mixin hover {
    &:hover, &:focus {
       @content;
    }
 }


// ---------------------------------------------------------
// 3. linear gradient
// ---------------------------------------------------------

/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-keyword or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
  }

  $conversion-map: (
          to top          : bottom,
          to top right    : bottom left,
          to right top    : left bottom,
          to right        : left,
          to bottom right : top left,
          to right bottom : left top,
          to bottom       : top,
          to bottom left  : top right,
          to left bottom  : right top,
          to left         : right,
          to left top     : right bottom,
          to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - $value;
}

// Add height of :before content, direction of gradient, and colors
@mixin linear-gradient($height, $direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }
  &:before{
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: $height;
    z-index: -5;
    /* fallback/image non-cover color */
    background: nth(nth($color-stops, 1), 1);
    /* Standard */
    background: linear-gradient($direction, $color-stops);
    /* Safari 5.1+, Chrome 10+ */
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    /* Safari 4+, Chrome 1+ */
    background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0%, $color-stops), color-stop($color-stops));
    /* Firefox 3.6+ */
    background-image: -moz-linear-gradient($direction, $color-stops);
    /* Opera 11.10+ */
    background-image: -o-linear-gradient($direction, $color-stops);
    /* IE10+ */
    background: -ms-linear-gradient($direction, $color-stops);
    /* IE6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$direction', endColorstr='$color-stops',GradientType=1 );
  }
}

// ---------------------------------------------------------
// 4. background image
// ---------------------------------------------------------

// path assets for Symfony 4
$imgPath : "../fo/img/";

@mixin bg-img($img, $bg-position, $bg-size, $bg-repeat) {
  background: url('#{$imgPath}/#{$img}') $bg-repeat $bg-position;
  background-size: $bg-size;
}

// ---------------------------------------------------------
// 5. Center div on absolute
// ---------------------------------------------------------
@mixin centeredAbsolute(){
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

// ---------------------------------------------------------
// 6. Hover button
// ---------------------------------------------------------

@mixin hoverBtn($bg-color, $hover-bg-color, $color, $hover-color){
  background-color: $bg-color;
  color: $color;
  &:hover, &:focus{
    background-color: $hover-bg-color;
    color: $hover-color;
  }
}

// ---------------------------------------------------------
// 7. box shadow effet
// ---------------------------------------------------------

@mixin shadow{
  -webkit-box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.1);
  box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.1);
}

// ---------------------------------------------------------
// 8. Transition
// ---------------------------------------------------------

@mixin transition{
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .35s ease-in-out;
  -ms-transition: all .35s ease-in-out;
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
}

// ---------------------------------------------------------
// 9. Rounded box
// ---------------------------------------------------------

@mixin roundedBox($size, $bg-color){
  width: #{$size}px;
  height: #{$size}px;
  background-color: $bg-color;
  color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// ---------------------------------------------------------
// 10. Fluid font size (responsive)
// ---------------------------------------------------------

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
      @each $property in $properties {
        #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
    }

    @media screen and (min-width: $max-vw) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}

// ---------------------------------------------------------
// 11. Blue box shadow
// ---------------------------------------------------------

@mixin blue-box-shadow{
  -webkit-box-shadow: 0px 6px 29px -13px rgba(92,164,199,0.85);
  -moz-box-shadow: 0px 6px 29px -13px rgba(92,164,199,0.85);
  box-shadow: 0px 6px 29px -13px rgba(92,164,199,0.85);
}

