// ---------------------------------------------------------
// LISTS
// ---------------------------------------------------------

.list-archives{
    margin-left: 0;
    li{
    padding: .5rem 0;
    border-bottom: 1px solid #c6d2e2;
        .archive-annee{
            font-size: 1rem;
            font-weight: 500;
            }
            .listeMois{
            padding: .5rem;
            li{
                &:last-child{
                    border-bottom: none;
                }
                .archive-mois{
                font-size: .8rem;
                }
            }
        }
    }
}