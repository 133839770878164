// ---------------------------------------------------------
// Reset
// ---------------------------------------------------------

* {
	&, &:before, &:after {
        box-sizing: border-box;
    }
    &:focus{
        outline: none;
    }
}

html{
  margin: 0;
  padding: 0;
  line-height: 1.4;
  box-sizing: border-box;
  //height: 100%;
  //scroll-behavior:smooth;
  //font-smoothing: antialiased;
  //text-rendering: optimizeLegibility;
  //font-size: 100%;
  // @include fluid-type(font-size, 320px, 1200px, 1rem, 1.2rem);
}

body{
  margin: 0;
  padding: 0;
  line-height: 1.4;
  font: calculate-rem($body-font-size)/1em $body-font-family;
  // overflow needed for mobile nav
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, ul, ol {
    margin: 0;
    padding: 0;
	font-weight: normal;
    font-family: $body-font-family;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: $base;
    line-height: 1.2;
    &{
      color: $base;
    }
    &:hover, &:focus{
      color: tint($base, 50%);
      text-decoration: none;
    }
}

figure {
  margin: 0;
  position: relative;
  max-width: 100%;
  img{
    width: 100%;
    max-width: 100%;
  }
}

img, svg{
  max-width: 100%;
}

meta{
  display: none;
}

p{
  line-height: 1.2;
  font-family: $body-font-family;
}

// Prevent blue highlight on Chrome
input,
textarea,
button,
select,
a {
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none;
  }
}

/* Always set the map height explicitly to define the size of the div
* element that contains the map. */
#map {
  height: 100%;
}