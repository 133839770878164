// ---------------------------------------------------------
// PAGE GRANDS PROJETS
// ---------------------------------------------------------

.block-body-grands-projets{
  .title-gradient{
    margin-bottom: 2rem;
    @media screen and (min-width: $screen-lg ){
      margin-top: 2rem;
      margin-bottom: 4rem;
    }
  }
  .card-base{
    .city-tag{
      margin-bottom: 0.25rem;
    }
    .category-tag{
      color: $gris-fonce;
      padding: 0;
      &:before{
        content: none;
      }
    }
  }
}