// ---------------------------------------------------------
// Plugin : SLICK
// ---------------------------------------------------------

// CONTAINER WIDTH
// $grid-container-width-xs: 540px;
// $grid-container-width-sm: 720px;
// $grid-container-width-md: 960px;
// $grid-container-width-xl: 1140px;

#grandsprojets-slider{
    
    @media screen and (min-width: $screen-xs) {
        left: 50%;
        margin-left: calc(-540px / 2 + 15px);
    }
    @media screen and (min-width: $screen-sm) {
        margin-left: calc(-720px / 2 + 15px);
    }
    @media screen and (min-width: $screen-md) {
        margin-left: calc(-960px / 2 + 15px);
    }
    @media screen and (min-width: $screen-xl) {
        margin-left: calc(-1240px / 2 + 15px); 
    }

    .slider-item{
        position: relative;
        .infos-wrapper{
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 1rem 1.5rem;
            color: white;
            @media screen and (min-width: $screen-lg ){
                padding: 1rem 2.5rem;
            }
            h3{
                font-family: $body-font-family;
                font-weight: 700;
                margin-bottom: 1rem;
                @include font-size(22);
                @media screen and (min-width: $screen-sm ){
                    @include font-size(32);
                }
                @media screen and (min-width: $screen-lg ){
                    @include font-size(42);
                }
            }

        }
        figure{
            border-radius: 4px;
            overflow: hidden;
            position: relative;
            @media screen and (max-width: $screen-lg ){
                max-height: 400px;
            }
            &:before{
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 0;
                width: 100%;
                background: rgb(0,0,0);
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);

                animation: cssAnimation .2s .5s forwards;
            }
            img{
                object-fit: initial;
                object-position: center;
                @media screen and (min-width: $screen-lg ){
                    max-width: 950px;
                    width: auto;
                    height: 615px;
                }
            }
        }
    }
    .slick-slide {
        margin: 0 .5rem;
    }

    .slick-prev-custom,
    .slick-next-custom {
        width: 50px;
        height: 50px;
        cursor: pointer;
        position: absolute;
        top: 3rem;
        z-index: 99;
        &:hover{
            opacity: .6;
            @include transition;
        }
    }
    .slick-prev-custom{
        left: 2.5rem;
    }
    .slick-next-custom{
        left: calc(50px + 3rem);
    }
}

#carouselAlter{
    position: relative;
    // list indicators
    .carousel-indicators{
        display: none;
        background-color: white;
        position: absolute;
        top: 0;
        width: fit-content;
        height: 30px;
        margin: 0 auto;
        padding: .3rem 2rem;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        li{
            width: 50px;
            &:after{
                background-color: $gris-clair;
            }
            &.active{
                &:after{
                    background-color: $bleu-fonce;
                }
            }
        }
    } // carousel indicators
    .carousel-inner{
        .carousel-item{
            // h2
            .titles-content{
                background-color: $gris-clair;
                padding: 1rem;
                .title-carousel{
                    font-weight: bold;
                    color: $bleu-fonce;
                    padding: 1rem 0;
                    font-size: 1.5rem;
                    @media screen and (min-width: $screen-sm ){
                        font-size: 3rem;
                    }
                }
                // h3
                .subtitle{
                    text-transform: uppercase;
                    color: $bleu-fonce;
                    font-size: 1rem;
                    @media screen and (min-width: $screen-sm ){
                        font-size: 2rem;
                    }

                }
            }
        }
        @media screen and (min-width: $screen-lg ){
            border-bottom-right-radius: 50px;
        }
    } // carousel inner
} // carouselAlter