// ---------------------------------------------------------
// TABLES
// ---------------------------------------------------------

table{
  @include blue-box-shadow;
  border: none;
  border-collapse: collapse;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  background-color: white;
  width: 100%;

  caption{
    @extend h4;
    margin: 1rem 0 2rem 0;
  }
  th{
    text-align: left;
  }
  td{
    border: none;
    padding: 15px;

    &:first-child{
      font-weight: bold;
      width: 50%;
      @media screen and (min-width: $screen-lg ){
        width: 55%;
      }
    }

  }
  tbody{
    tr{
      border: none;
      &:nth-child(odd) {
        background: #ecf1f1;
      }
      &:first-child {
        background: $vert-deau;
        font-size: 18px;
        color: #fff;
        line-height: 1.4;
        // th of first tr table
        td{
          padding-left: 18px;
          padding-right: 18px;
        } // th of first tr table
      } // :first child
    } // tr
  }
}

#terrains{
  table{
    tbody{
      tr{
        &:first-child {
          background: white;
          color: #212529;
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }
}

.table-appels-doffres{
  .row{
    border-bottom: 1px solid $gris-clair;
  }
}