// ---------------------------------------------------------
// PAGE APPELS DOFFRES
// ------------------------------------------------------

.block-main-appels-doffres{

    .main-title{
        text-align: center;
        color: $bleu-fonce;
        padding: .5rem;
        @media screen and (min-width: $screen-sm ){
            padding: 5rem 1rem 1rem 4rem;
        }
        span{
            font-weight: bold;
        }
        &:after{
            display: flex;
            margin: 1rem auto;
            width: 10%;
            content: '';
            border: $bleu-fonce 4px solid;
        }
    }

.block-section-table{
    @media screen and (min-width: $screen-lg ){
        padding: 2rem 0;
    }
    // Boutons
    .btn-mobile{
        width: fit-content;
        margin: .5rem auto;
        display: flex;
        padding: 1rem 2rem;
        background-color: $vert-deau;
        border-color: $vert-deau;
    }

    .title-table{
        font-size: 1rem;
        background-color: $bleu-fonce;
        color: white;
        padding: 1rem 0;
        text-align: center;
    }

    .border-bot{
        margin: 3rem auto;
        @media screen and (min-width: $screen-lg ){
            border: 10px solid white;
            width: fit-content;
            border-radius: 10px;
            -webkit-box-shadow: 1px -7px 19px 2px rgba(0,0,0,0.05);
            -moz-box-shadow: 1px -7px 19px 2px rgba(0,0,0,0.05);
            box-shadow: 1px -7px 19px 2px rgba(0,0,0,0.05);
        }
    }


    .table-appels-doffres{
        width: 100%;
        background-color: white;
        margin-bottom: 1rem;
        @media screen and (min-width: $screen-lg ){
            margin-bottom: 0;
        }
        #telecharger{
            display: flex;
            align-items: center;
            background: #f6f6f6;
            margin: .5rem 0;
        }
        .telechargement {
            word-wrap: break-word;
        }
        .col-body{
            &:before{
                content: attr(data-title);
                background-color: $bleu-fonce;
                width: 100%;
                padding: 1rem;
                color: white;
                display: flex;
                cursor: pointer;
                @media screen and (min-width: $screen-lg ){
                    display: none;
                }
            } // :before
            p{
                padding: 1rem;
                margin: 0;
                line-height: 1.2rem;
                @media screen and (min-width: $screen-lg ){
                    padding: 1rem .3rem;
                }
                @media screen and (min-width: $screen-xl ){
                    padding: 1rem;
                    text-align: center;
                }
                &.reference, &.remise, &.telechargement{
                    font-weight: bold;
                }
            }
            .download-link{
                display: flex;
                width: 40px;
                height: 40px;
                background-color: $bleu-fonce;
                border-radius: 100%;
                padding: .75rem;
                margin: 1rem auto;
                svg{
                    width: 100%;
                    height: 100%;
                }
            }
        } // col body
    }
    
}
}