// ---------------------------------------------------------
// FORMS
// ---------------------------------------------------------

$heightSelect: 44px;

.form-group{
  label{
    display: block;
    margin-bottom: .5rem;
  }
}

.block-main-operations{
  .form-group{
    &:nth-child(2){
      .select2-container--default{
        width: 128px !important;
      }
    }
  }
}

.block-main-terrains{
  .content-labels-inputs{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    #informations-slider{
      margin-bottom: .5rem;
      display: block;
      color: $bleu-fonce;
      text-align: center;
    }
  }
}

.noUi-connect{
  background: $bleu-fonce !important;
}

.form-group{
  &:nth-child(2){
    .select2-container--default{
      width: 100% !important;
    }
  }
}

.form-group-inline{
  display: flex;
  align-items: center;
}

.form-group-email{
  width: 70%;
  position: relative;
  @media screen and (min-width: $screen-xxl ){
    width: 65%;
  }
  i{
    margin-right:2rem;
  }
  }


  .form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid $color-dark-blue;
    outline: 0;
    font-size: 1rem;
    color: $color-dark-blue;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    opacity: .2;
    position: relative;
    z-index: 2;
    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ .form__label {
      @include fluid-type(font-size, 320px, 1200px, .85rem, 1.2rem);
      cursor: text;
      top: 10px;
    }
  }

  .form__label {
    position: absolute;
    top: -8px;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $color-dark-blue;
    opacity: .25;
    left: 4px;
    z-index: 0;
  }

  #formNewsletter{
    .form__label{
      @media screen and (min-width: $screen-lg ){
        left: calc(41px + 2rem);
      }
    }
  }

  .form__field:focus {

    ~ .form__label {
      position: absolute;
      top: -13px;
      left: 0;
      display: block;
      transition: 0.2s;
      font-size: 1rem;
      color: $color-dark-blue;
      font-weight:700;
      opacity: 1;
      @media screen and (min-width: $screen-lg ){
        left: calc(41px + 2rem);
      }
    }
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    opacity: 1;
  }
  /* reset input */
  .form__field{
    &:required,&:invalid { box-shadow:none; }
  }

#formContact{
  margin: 2rem 0;
  .form-group-inline{
    @extend .form-group-email;
    width: 90%;
    margin-bottom: 1.2rem;
    label{
      @extend .form__label;
    }
    input{
      @extend .form__field;
    }
  }
  .form-group-textarea{
    @extend .form-group-inline;
    margin-top: 2rem;
    textarea{
      margin-top: 1rem;
    }
    label{
      font-size: 1.2rem;
    }
  }
  .form__field:focus {
    ~ .form__label {
      @media screen and (min-width: $screen-lg ){
        left:4px;
      }
    }
  }
}

.select2-container--default{
  margin-right: 10px;

  .select2-selection--single{
    background: rgba($borderblue, .5);
    border: 1px solid $borderblue;
    border-radius: 25px;
    height: $heightSelect;
    display: flex;
    align-items: center;
    .select2-selection__rendered{
      color: $bleu-fonce;
      margin-left: 8px;
    }
    .select2-selection__arrow{
      height: $heightSelect;
      margin-right: 12px;
      b {
        border-color: $bleu-fonce transparent transparent;
        border-width: 7px 6px 0;
      }
    }

  }
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b{
  border-color: $bleu-fonce transparent transparent;
  border-width: 7px 6px 0;
  @include transition;
  transform: rotate(180deg);
}

.select2-results__options{
  li{
    color: $bleu-fonce;
    &:hover{
      background: rgba($borderblue, .5);
      cursor: pointer;
      @include transition;
    }
  }
}

.form-group-checkboxes{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .25rem;
  label{
    display: inline-block;
    background-color: rgba(255, 255, 255, .9);
    color: $bleu-fonce;
    border: 1px solid $borderblue;
    border-radius: 25px;
    white-space: nowrap;
    margin: 3px 10px 3px 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    padding: 8px 12px 8px 12px;
    cursor: pointer;
    @include transition;
    position: relative;
    &:after, &:before{
      content: none;
      @include transition;
    }
    &:after{
      transform: rotate(45deg);
    }
    &:before{
      transform: rotate(-45deg);
    }
    &:hover, &:focus{
      background-color: $gris-clair;
      @include transition;
    }
  }
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }
  input[type="checkbox"]:checked + label {
    border: 2px solid $color-dark-blue;
    @include transition;
    padding: 8px 24px 8px 12px;
    &:before , &:after{
      content: '';
      position: absolute;
      right: 12px;
      top: 8px;
      height: 13px;
      width: 2px;
      background-color: $base;
    }
  }
}


