// ---------------------------------------------------------
// PAGE RECHERCHE
// ---------------------------------------------------------


.block-main-recherche{
    padding: 2rem 0;

    .card-base{
        figure{
            height: auto;
        }
    }
    //h1
    .resultat-recherche{
        text-align: center;
        color: $bleu-fonce;
        padding: 1rem 0;
    }
    .system{
        color: $bleu-fonce;
        font-size: 1.2rem;
    }
    .content-result{
        padding: 1rem 0;
        h2{
            padding: 1rem 0;
            color: $bleu-fonce;
            font-weight: 600;
        }
        .card{
            padding: 1rem;
            line-height: 1.2rem;
            background-color: white;
            a{
                padding: 1rem 2rem;
                background-color: $bleu-fonce;
                color: white;
                display: flex;
                width: fit-content;
                margin: 0 auto;
                text-align: center;
                border-bottom-right-radius: 20px;
                @media screen and (min-width: $screen-lg ){
                    text-align: center;
                    margin: 0;
                }
            }
        }
    }
}