// ---------------------------------------------------------
// BUTTONS
// ---------------------------------------------------------

.btn{
  padding: .5rem 1rem;
  border-radius: 30px;
  margin-bottom: .85rem;
  display: inline-flex;
  font-size: .85rem;
  transition: all .35s ease;
  box-sizing: border-box;
  &:hover{
    @include transition;
    padding: .5rem 1.5rem;
  }
  &:focus{
    box-shadow: none;
  }
}

.btn-submit{
  @extend .btn;
  background-color: #022C63;
  color: white;
  padding: 1rem;
  border-radius: 80px;
  border: none;
  font-size: 1rem;
  @media screen and (min-width: $screen-lg ){
    padding: 1rem 2rem;
    float: right;
  }

  &:hover{
    background-color: tint(#022C63, 20%);
    color: white;
    padding: 1rem;
    @media screen and (min-width: $screen-lg ){
      padding: 1rem 2rem;
    }
  }

}
.btn-green{
  background-color: #B1C903;
}
.btn-center{
  float: none;
}

.btn-outlined{
  padding: .5rem 1rem;
  border-radius: 30px;
  margin-bottom: .85rem;
  display: inline-flex;
  font-size: .85rem;
  transition: all .35s ease;
  box-sizing: border-box;
  color: $bleu-fonce;
  border: 1px solid $gris-clair;
  background-color: white;
  &:hover{
    background-color: $gris-clair;
    transition: all .35s ease;
    padding: .5rem 1.5rem;
  }
}

.btn-outlined-highlight{
  @extend .btn-outlined;
  border: 2px solid $bleu-fonce;
  &:hover{
    background-color: $bleu-fonce;
    transition: all .35s ease;
    padding: .5rem 1.5rem;
    color: white;
  }
}


.btn-video{
  color: $vert-deau;
  font-weight: 500;
  display: flex;
  align-items: center;
  img{
    margin-right: .5rem;
  }
}

.card-btn{
  @extend .btn;
  background-color: $bleu-fonce;
  color: white;
  margin-bottom: 0;
  &:hover{
    background-color: $bleu-clair;
    color: white;
  }
}

.btn-gallery-float{
  @extend .btn-outlined;
  position: absolute;
  z-index: 5;
  bottom: 2rem;
  right: 3rem;
  cursor: pointer;
  @media screen and (min-width: $screen-lg ){
    bottom: 4rem;
  }
}

.btn-aside{
  background-color: $bleu-clair;
  padding: 1rem;
  color: white;
  display: flex;
  border: none;
  width: 100%;
  margin-bottom: 1rem;
  &:focus{
    outline: none;
  }
  @media screen and (min-width: $screen-lg ){
    display: none;
  }
}