// ---------------------------------------------------------
// TITLES
// ---------------------------------------------------------
h3{
	@media screen and (max-width: $screen-lg){
		font-size: 1.25rem;
	}
}
.title-gradient{
	font-weight: 700;
	font-size: 2rem;
	background: -webkit-linear-gradient(45deg, #052C63, #27B3B1);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	@media screen and (min-width: $screen-lg){
		font-size: 3rem;
	}
}

.title-gradient-mb{
	margin-bottom: 2rem;
	@media screen and (min-width: $screen-lg){
		margin-bottom: 4rem;
	} // query
}

.inner-title{
	font-size: 1.55rem;
	font-weight: 500;
	margin-bottom: .5rem;
}

.main-title{
	color: $color-dark-blue;
	@include font-size(35);
	font-weight: bold;
	margin-bottom: 1rem;
}

h2.hintro{
	color: $base;
	font-size: 1.15rem;
    margin-top: 1.5rem;
    font-weight: 500;
    @media screen and (min-width: $screen-lg){
    	font-size: 1.35rem;
    	margin-top: 1.85rem;
    }
}
h3.section-op{
	color: $base;
	font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #d3dae4;
    margin-top: 3rem;
}

.sub-titles-with-h1{
	align-items: center;
	justify-content: space-between;
	span{
		color: $vert-clair;
		text-transform: uppercase;
	}
	h1{
		color: $bleu-fonce;
		font-weight: 500;
		margin-bottom: .5rem;
		font-size: 1.5rem;
		@media screen and (min-width: $screen-lg){
			font-size: 2rem;
			margin-bottom: 2rem;
		} // query
	}
	.first-title{
		font-weight: 500;
		margin-bottom: 1rem;
		font-size: 1.7rem;
		line-height: 1;
		@media screen and (max-width: $screen-lg ){
        	font-size: 1.5rem;
      	}
	}
}

.sub-titles{
	align-items: center;
	justify-content: space-between;
	span{
		color: $vert-clair;
		text-transform: uppercase;
	}
	h2{
		color: $bleu-fonce;
		font-weight: 500;
		margin-bottom: .5rem;
		font-size: 1.7rem;
		@media screen and (min-width: $screen-lg){
			font-size: 2rem;
			margin-bottom: 2rem;
		} // query
	}
}

.subtitle{
	color: $bleu-fonce;
	margin-bottom: 2rem;
	@media screen and (min-width: $screen-lg){
		font-size: 1rem;
		width: 75%;
	}
	&:last-child{
		margin-bottom: 0;
	}
}

.title-aside-h2{
	@extend h4;
}

.title-aside-h3{
	@extend h5;
	font-weight: 500;
	color: $bleu-fonce;
}

.title-download{
	padding: 1rem 1rem 0 1rem;
}
