// ---------------------------------------------------------
// FOOTER
// ---------------------------------------------------------

#formNewsletter{
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    color: $bleu-fonce;
    padding: 1rem 2rem;
    border-radius: 30px;
    @media screen and (min-width: $screen-lg ){
        height: 110px;
        padding: 0 5rem;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 110px;
    }
    i{
        display: none;
        @media screen and (min-width: $screen-lg ){
            display: block;
        }
    }
    .form-group-email{
        width: 90%;
        margin: 1rem auto;
        text-align: center;
        @media screen and (min-width: $screen-lg ){
            display: flex;
            width: 70%;
        }
    }

}

.col-absolute{
    margin-bottom: 1rem;
    margin-top: 1rem;
    @media screen and (min-width: $screen-lg ){
        position: absolute;
        top: -3rem;
        margin-bottom: 0;
        margin-top: 0;
    }
}

.contact-list{
    li{
        display: flex;
        align-items: center;
        line-height: 1.3;
        i{
            margin-right: 1rem;
        }
        strong{
            line-height: 1.4;
        }
        a{

            display: flex;
            align-items: center;
        }
    }
}


.block-footer{
    background-color: #214577;
    color: white;
    position: relative;
    @media screen and (min-width: $screen-lg){
        margin-top: 6rem;
        padding: 7rem 0 0 0;
    }
    .bg-dark-blue{
        padding: 1rem 0;
        margin-top: 3rem;
        ul{
            display: flex;
            align-items: center;
            justify-content: center;
            li{
                opacity: .5;
                padding: 0 1rem;
            }
        }
    }
    .logo-figure{
        height: 65px;
        width: 140px;
        margin-bottom: 2rem;
    }
    .social-links{
        display: flex;
        align-items: center;
        li{
            a{
                i{
                    @include transition;
                    transform: scale(1);
                }
               &:hover{
                   i{
                      &:before{
                          @include transition;
                          transform: scale(1.1);
                      }
                   }
               }
            }
        }
    }
    ul{
        li{
            color: lighten($color: #fff, $amount: 5%);
            font-weight: 100;
            padding: .1rem 0;
            a{
                color: white;
                padding: 0;
                &:hover{
                    color: lighten($color: #559bcf, $amount: 30%);
                }
            }
        }
    }
    h3{
        text-transform: uppercase;
        font-weight: bold;
        font-size: .9rem;
        padding-bottom: 1rem;
    }
}

.block-body-front-page{
    .animated-bg{

    }
}



