// ---------------------------------------------------------
// PAGE CMS
// ---------------------------------------------------------

.block-main-cms{
    color: $bleu-fonce;
    font-size: 1rem;
    a{
        color: $vert-deau;
    }
    img{
        width: 100%;
        height: auto;
        border-radius: 4px;
        overflow: hidden;
        margin: 2rem 0;
    }
    .content-text{
        &.introduction{
            line-height: 1;
            margin-bottom: 2rem;
            color: #668dc3;
            font-weight: bold;
        }
    }
    .block-section-p5{
        padding: 3rem 0;
    }
    .card-blue-shadow-vertical{
        width: 80%;
        margin: 1rem .8rem;
        padding: 1.5rem 2rem;
        h2{
            font-size: 1.5rem;
            font-weight: 400;
        }
        h3{
            font-size: 1.3rem;
            font-weight: 400;
        }
    }
    .main-title{
        text-align: center;
        color: $bleu-fonce;
        font-weight: bold;
        margin: 2rem auto 3rem auto;
        &:after{
            width: 10%;
            content: '';
            display: flex;
            margin: 1rem auto;
            border: $bleu-fonce 4px solid;
        }
    }

    h2, h3, h4, h5{
        font-weight: 500;
        margin-top: 2.5rem;
        margin-bottom: 1rem;
        color: $base;
        &:first-child{
            margin-top: 0;
        }
    }

    p{
        color: $base;
        line-height: 1.4;
        font-size: 1rem;
        font-family: $body-font-family;
    }

    div{
        @extend p;
    }

    table{
        width: 100% !important;
    }

    ul{
        @extend p;
        margin-left: 1rem;
        font-style: initial;
        @media screen and (min-width: $screen-lg ){
            margin-left: 3rem;
        }
        li{
            list-style-type: circle;
            margin-bottom: .25rem;
        }
    }

    .col-align{
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (min-width: $screen-lg ){
            align-items: flex-end;
        }
        .card-blue-shadow-vertical{
            p{
                strong{
                    font-size: 1.5rem;
                }
            }
        }
    }

    .row{
        &:first-child{
            padding-bottom: 3rem;
        }
    }

    @media screen and (min-width: $screen-lg){
        .animated-bg{
            i{
                top: 550px;
                right: -400px;
            }
        }
    } //query
}

@media screen and (min-width: $screen-lg){
    .block-body-cms{
        .block-footer{
            .animated-bg{
                display: none;
            }
        }
    }
}

.block-main-cms{

    .block-section-secondary{
        // background color on row
        .colored{
            background-color: $gris-clair;
            padding-bottom: 2rem;
            @media screen and (min-width: $screen-xl ){
                margin-top: -7rem;
            }
        }
    }

    // Col chiffre
    .chiffre{
        color: $bleu-fonce;
        text-align: center;
        strong{
            font-size: 3em;
        }
        .title-col{
            font-weight: 600;
            padding: .3rem;
        }
        &:before{
            width: 100%;
            content: '';
            display: inline-block;
            margin: 1rem auto;
            border: $bleu-fonce 4px solid;
        }
    }
}

.col-sticky{
    position: sticky;
    top: 200px;
}