// ---------------------------------------------------------
// HEADER : Navbar
// ---------------------------------------------------------


/** Mobile Bar **/
    .toggle-nav {
        display: none;
    }

    .mobile-bar {
        z-index: 5000;
        position: fixed;
        width: 100%;
        height: 60px;
        background-color: white;
        -webkit-box-shadow: 0px 1px 5px rgba(0,0,0,0.3);
        box-shadow: 0px 1px 5px rgba(0,0,0,0.3);
        text-align: center !important;
        label {
            position: absolute;
            top: 0; 
            left: 10px;
            width: 60px;
            height: 60px;
            text-align: center;
            cursor: pointer;
            -webkit-transition: -webkit-transform 500ms ease;
            transition: -webkit-transform 500ms ease;
            transition: transform 500ms ease;
            transition: transform 500ms ease, -webkit-transform 500ms ease;
            &:after {
                content: "\2630";
                font-size: 2em;
                color: $bleu-fonce;
                line-height: 60px;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                -webkit-transition: color 500ms ease;
                transition: color 500ms ease;
            }
        }
        img{
            margin: 5px auto;
            width: auto;
        }
        @media screen and (min-width: $screen-sm) {
            display: none;
        }
    }


/** Slide-Out Effect **/
    .toggle-nav:checked ~ .site-content-contain{ /** when the checkbox is checked the container slides-out **/
        -webkit-transform: translateX(70%);
        transform: translateX(70%);
        -webkit-transform: translate3d(70%, 0, 0);
        transform: translate3d(70%, 0, 0);
    }
    .toggle-nav:checked ~ header { /** when the checkbox is checked the header slides-out **/
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        position: fixed;
        overflow: scroll;
    }

/** Label Effect (Menu Icon) **/
    .toggle-nav:checked + .mobile-bar label {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

/** Container **/
    .site-content-contain{
        position: relative;
        -webkit-transition: -webkit-transform 500ms ease;
        transition: -webkit-transform 500ms ease;
        transition: transform 500ms ease;
        transition: transform 500ms ease, -webkit-transform 500ms ease;
        @media screen and (max-width: $screen-sm) {
            padding-top: 60px;
        }
    }

/** Viewport >= 768px **/
    @media (min-width: 769px) {
        
        .mobile-bar {
            display: none;
        }
        
        .toggle-nav:checked ~ .site-content-contain {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
        
        .site-content-contain {
            -webkit-transition: none;
            transition: none;
        }
    }


.block-main-home-page, .block-main-operations, .block-main-terrains{
    // ul
    .nav-tabs{
        width: fit-content;
        margin: 1rem auto;
        border-bottom: none;
        // li
        .nav-item{
            //a
            .nav-link{
                border: none;
                color: $bleu-fonce;
                font-size: 1.2rem;
                position: relative;
                &:after{
                    position: absolute;
                    z-index: 0;
                    bottom: -50%;
                    left: 0;
                    width: 100%;
                    content: '';
                    border: darken($color: #3b5b88, $amount: 0) 2px solid;
                    border-radius: 4px;
                }
                &.active{
                    font-weight: bold;
                    &:after{
                        border: $bleu-fonce 4px solid;
                    }
                }
            }
        }
    } // ul
}