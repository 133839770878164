// ---------------------------------------------------------
// SLIDER
// ---------------------------------------------------------


.carousel-into-modal{
  .carousel-inner{
    .carousel-item{
      color: $bleu-fonce;
      strong{
        font-weight: bold;
      }
      figure{
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: .5rem;

        img{
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  } // .carousel-inner
  .carousel-control-prev{
    max-height: 80%;
  }
  p{
    margin: 0;
  }
  .credit{
    font-size: .8rem;
    margin-top: .35rem;
  }
}