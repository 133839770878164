// ---------------------------------------------------------
// Keyframes animations
// ---------------------------------------------------------

@-webkit-keyframes wave1 {
  0% {
    -webkit-transform: scale(5.5);
    transform: scale(5.5);
    opacity: 0.3; }
  100% {
    -webkit-transform: scale(8.5);
    transform: scale(8.5);
    opacity: 0.0; } }

@keyframes wave1 {
  0% {
    -webkit-transform: scale(5.5);
    transform: scale(5.5);
    opacity: 0.3; }
  100% {
    -webkit-transform: scale(8.5);
    transform: scale(8.5);
    opacity: 0.0; } }

@-webkit-keyframes wave2 {
  0% {
    -webkit-transform: scale(3.5);
    transform: scale(3.5); }
  100% {
    -webkit-transform: scale(5.5);
    transform: scale(5.5); } }

@keyframes wave2 {
  0% {
    -webkit-transform: scale(3.5);
    transform: scale(3.5); }
  100% {
    -webkit-transform: scale(5.5);
    transform: scale(5.5); } }

@-webkit-keyframes wave3 {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    -webkit-transform: scale(3.5);
    transform: scale(3.5); } }

@keyframes wave3 {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    -webkit-transform: scale(3.5);
    transform: scale(3.5); } }




@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}


@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(340deg);
  }
}

@keyframes tonext {
  75% {
    left: 0;
  }
  95% {
    left: 100%;
  }
  98% {
    left: 100%;
  }
  99% {
    left: 0;
  }
}

@keyframes tostart {
  75% {
    left: 0;
  }
  95% {
    left: -300%;
  }
  98% {
    left: -300%;
  }
  99% {
    left: 0;
  }
}

@keyframes snap {
  96% {
    scroll-snap-align: center;
  }
  97% {
    scroll-snap-align: none;
  }
  99% {
    scroll-snap-align: none;
  }
  100% {
    scroll-snap-align: center;
  }
}


@media screen and (min-width: $screen-lg){

    .animated-bg{
      i{
        top: -450px;
        right: -100px;
        position: absolute;
        height: 90px;
        width: 90px;
        border-radius: 100%;
        opacity: .2;
        z-index: -1;
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        -webkit-animation: wave1 3s linear infinite;
        animation: wave1 3s linear infinite;
        background: #fff6f6;
        box-shadow: 0 15px 30px 0 rgb(126, 136, 190);
        &:nth-child(2){
          -webkit-animation: wave2 3s linear infinite;
          animation: wave2 3s linear infinite;
        }
        &:nth-child(3){
          -webkit-animation: wave3 3s linear infinite;
          animation: wave3 3s linear infinite;
        }
      }
    }
}

// to display figure befre element
@keyframes cssAnimation {
  to   { height: 50%; }
}