// ---------------------------------------------------------
// PAGE SINGLE ACTUALITES
// ---------------------------------------------------------

.block-main-single-actu{
    .main-title{
        font-weight: bold;
        color: $bleu-fonce;
        padding: 3rem 0 0 0;
        font-size: 2rem;
        text-align: center;
    }
    .subtitle{
        color: $bleu-fonce;
        font-size: 1.3rem;
        text-align: center;
        text-transform: uppercase;
        padding: .5rem 0;
    }
    
    .rounded-img{
        width: 100%;
        border-top-right-radius: 50px;
        overflow: hidden;
    }

    .content-actu{
        padding: 1rem 4rem;
        h2{
            color: $vert-deau;
            padding-bottom: 1rem;
            font-size: 1.5rem;
            font-weight: 700;
        }
        h3{
            color: $bleu-fonce;
            padding-bottom: 1rem;
            font-size: 1.5rem;
        }
        p{
            color: $bleu-fonce;
            line-height: 1.2rem;
        }
    }
}