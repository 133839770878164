.cc-window{
  &.cc-banner{
    width: calc(100% - 6rem);
    border-radius: 10px;
    overflow: hidden;
    left: 3rem;
    right: 0;
    bottom: 3rem;
    padding: 1rem;
    -webkit-box-shadow: 0px 6px 29px -13px rgba(92,164,199,0.85);
    -moz-box-shadow: 0px 6px 29px -13px rgba(92,164,199,0.85);
    box-shadow: 0px 6px 29px -13px rgba(92,164,199,0.85);
    @media screen and (min-width: $screen-lg ){
      width: 30%;
    }
    span{
      color: #3B5B88;
    }
    .cc-btn{
      border-radius: 10px;
    }
  }
  .cc-message{

  }
}

.cc-color-override-1650916351 .cc-link, .cc-color-override-1650916351 .cc-link:active, .cc-color-override-1650916351 .cc-link:visited{
  color: #3B5B88 !important;
  padding: 0;
}