.block-main-actualites{
    #liste-actualites{
        a{
            color: $bleu-fonce;
            .card{
                width: 100% !important;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                margin-bottom: 2rem;
                -webkit-box-shadow: 1px -7px 19px 2px rgba(0,0,0,0.05);
                -moz-box-shadow: 1px -7px 19px 2px rgba(0,0,0,0.05);
                box-shadow: 1px -7px 19px 2px rgba(0,0,0,0.05);
                text-align: center;
                border-bottom: 10px solid $bleu-fonce;
                @media screen and (min-width: $screen-sm ){
                    min-height: 95%;
                }
                .card-header{
                    background-color: white;
                    border-bottom: none;
                    padding: 1.25rem;
                    .date{
                        color: $bleu-fonce;
                        text-align: center;
                        padding-bottom: 1rem;
                    }
                    .location{
                        padding: 2rem 0;
                        text-align: center;
                        text-transform: uppercase;
                        background-color: $bleu-fonce;
                        color: white;
                        font-size: 1.5rem;
                        font-weight: 500;
                        letter-spacing: .1rem;
                    }
                }
                .card-body{
                    text-align: center;
                    .card-subtitle{
                        font-weight: 100;
                        color: $bleu-fonce;
                        text-transform: uppercase;
                        padding-top: 2rem;
                        padding: 0;
                    }        
                    .card-title{
                        margin: 1rem 0 2rem 0;
                        position: relative;
                        font-size: 1.2rem;
                        font-weight: bold;
                        a{
                            font-weight: bold;
                            color: $bleu-fonce;
                        }
                    }
                    .card-text{
                        padding: 0;
                    }
                    p{
                        text-align: center !important;
                        color: $bleu-fonce;
                        font-weight: 200;
                        padding: 2rem 2rem 1rem 2rem;
                        line-height: 1.3rem;
                        @media screen and (min-width: $screen-sm ){
                           font-size: .9rem;
                           line-height: 1.3rem;
                        }
            
                    }
            
                } // card body
                .card-footer{
                    background-color: white;
                    border: none;
                    .content-svg{
                        width: 50px;
                        height: 50px;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        padding: 1rem;
                        margin: 1rem auto;
                        background-color: $bleu-fonce;
                        svg{
                            width: 100%;
                            height: 100%;
                        }
                    }
                } 
            } // card   
        }
    }
}