// ---------------------------------------------------------
// TABS
// ---------------------------------------------------------

// reset
.nav-tabs{
  padding: 1rem;
  .nav-link{
    border: none;
    text-transform: uppercase;
    color: white;
    border-radius: 3px;
    background-color: #b0bbcb;
    margin: 5px;
    &.active{
      background-color: $vert-clair;
      color: white;
      &:hover{
        background-color: tint(#B1C903, 20%);
        @include transition;
      }
    }
    &:hover{
      background-color: darken(#D3DAE4, 20%);
      @include transition;
    }
  }
}

.tabs{
  display: none;
  @media screen and (min-width: $screen-lg ){
    display: block;
    margin: 2rem 0 2rem;
    @include blue-box-shadow;
  }
}

.block-main-single-operations{

  // System mobile responsive tab accordion
  // .tab-pane, .tab-content{
  .tab-pane{
    position: relative;
    @media screen and (max-width: $screen-lg ){
      display: block;
      &:before{
        content: attr(data-title);
        background-color: #b0bbcb;
        width: 100%;
        padding: 1rem;
        color: white;
        display: flex;
        cursor: pointer;
        border: none;
        text-transform: uppercase;
        color: white;
        border-radius: 3px;
        margin: 5px 0;
      }
      &:after{
        position: absolute;
        top: 23%;
        right: 8%;
        content: "";
        border: solid white;
        border-width: 0 2px 2px 0;
        display: block;
        padding: 4px;
        margin-top: 7px;
        margin-left: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        @media screen and (min-width: $screen-sm ){
          right: 3%;
        }
        @media screen and (min-width: $screen-lg ){
          display: none;
        }
      } // :after
      &.rotate{
        &:after{
          top: 15px;
          transform: rotate(45deg);
          @include transition;
        }
      }
    }
    &.fade{
      @media screen and (max-width: $screen-lg ){
        opacity: 1;
      }
    }
    &.rotate{
      .item-content{
        display: block;
      }
    }

    .item-content{
      display: none;
      padding: 1rem 0;
      @media screen and (min-width: $screen-lg ){
        padding: 1rem 0 2rem 0;
        display: flex;
        flex-direction: column;
      }
      h2, h3, h4, h5{
        font-weight: 500;
        margin-top: 2rem;
        color: $base;
        &:first-child{
          margin-top: 0;
        }
      }
      p{
        color: $base;
        line-height: 1.4;
        font-size: 1rem;
        font-family: $body-font-family;
      }
    } // .item-content

    .item-toggle{
      @media screen and (max-width: $screen-lg ){
        display: none;
      }
    }
    .item-show{
      display: block;
    }

  } // .tab-pane
}

