// ---------------------------------------------------------
// BLOCK ASIDE
// ---------------------------------------------------------

.block-aside{
  position: relative;
  @include blue-box-shadow;
  margin-bottom: 3rem;
  color: $bleu-fonce;

  &.block-aside-negative-margin{
    @media screen and (min-width: $screen-lg ){
      margin-top: -7rem;
    }
  }
  @media screen and (min-width: $screen-lg ){
    margin-top: 2rem;
    margin-right: 1rem;
    z-index: 10;
    background-color: white;
    border-radius: 15px;
    padding: 1rem;
  }
  .block-responsable{
    background-color: $bleu-fonce;
    color: white;
    padding: 1rem;
    border-radius: 4px;
    @media screen and (min-width: $screen-xl ){
      padding: 2rem;
    }
    p{
      margin-bottom: 0;
    }
    .title-aside-h2{
      margin-bottom: .35rem;
      @media screen and (max-width: $screen-lg ){
        font-size: 1.15rem;
      }
    }
    .fonction{
      margin-top: 1rem;
    }
    .name{
      display: flex;
      align-items: center;
      padding: .25rem 0;
      svg{
        margin-right: 1rem;
      }
      p{
        font-weight: 100;
        margin: 0;
      }
    } // name
    .contact{
      display: flex;
      padding: .25rem 0;
      align-items: center;
      margin-bottom: .85rem;
      svg{
        margin-right: 1rem;
      }
      p{
        font-weight: 100;
        margin: 0;
      }
    }
  } // block responsables
  .block-donnees{
    padding: 2rem 1rem;
    color: $bleu-fonce;
    @media screen and (min-width: $screen-lg ){
      padding: 1rem;
    }
    @media screen and (min-width: $screen-xl ){
      padding: 2rem;
    }
    .title-aside-h3{
      margin-bottom: 15px;
      @media screen and (max-width: $screen-lg ){
        font-size: 1.15rem;
      }
    }
    p{
       margin: 0;
     }

  } // block donnees
  .block-download{
    background-color: $vert-clair;
    color: white;
    padding: 1rem;
    a{
      color: white;
    }
    @media screen and (min-width: $screen-xl ){
      padding: 2rem 2rem 3rem 2rem;
    }
  } // block-download
  .block-area{
    background-color: $vert-deau;
    color: white;
    border-radius: 4px;
    @media screen and (min-width: $screen-lg ){
      padding: 1rem;
    }
    @media screen and (min-width: $screen-xl ){
      padding: 2rem;
    }
    .title-aside-h2{
      margin-bottom: 1rem;
    }
    .content{
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      i{
        margin-right: .75rem;
      }
      p{
        margin: 0;
        font-size: 1rem;
      }
    }
  } // .block-area (terrains)
} // .block-aside


// ----- V1
// Largeur du bloc ASIDE dans commons.scss

.block-aside-actualites{
  padding: 1rem 2rem;
  // section balise
  .download{
    background-color: $vert-deau;
    text-align: center;
    .download-link{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      color: white;
      figure{
        padding: 1rem;
      }
    }
  }
  .archives{
    padding: 1rem 0;
    h2{
      font-size: 1.3rem;
      margin-bottom: 1rem;
      font-weight: bold;
    }
  
  }
}