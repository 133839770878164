// ---------------------------------------------------------
// PAGE NOS METIERS
// ---------------------------------------------------------

.block-main-nos-metiers{
    
    .block-section-logo{
        text-align: center;
        .main-title{
            text-align: center;
            color: $bleu-fonce;
            padding: .5rem;
            @media screen and (min-width: $screen-sm ){
                padding: 5rem 1rem 1rem 2rem;
            }
            span{
                font-weight: bold;
            }
            &:after{
                position: absolute;
                z-index: 0;
                bottom: -8%;
                left: 45%;
                width: 10%;
                content: '';
                border: $bleu-fonce 4px solid;
            }
        }
        img{
            margin: 2rem auto;
            width: 40%;
        }
        .bg-svg-top{
            @media screen and (min-width: $screen-xl ){
                margin-top: -3rem;
            }
        }
    }
    .block-section-cards{
        padding: 0 0 6rem 0;
        background-color: $gris-clair;
        @media screen and (min-width: $screen-sm ){
            margin-top: -3rem;
        }
        @media screen and (min-width: $screen-lg ){
            margin-top: -4rem;
        }
        @media screen and (min-width: 1600px ){
            margin-top: -8rem;
        }
        .subtitle{
            text-align: center;
            color: $bleu-fonce;
            padding: 1rem 0;
            line-height: 1.4rem;
            @media screen and (min-width: $screen-sm ){
                text-align: left;
                padding: 2.7rem 0;
            }
        }
        .card{
            width: 100% !important;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            margin-top: 2rem;
            -webkit-box-shadow: 1px -7px 19px 2px rgba(0,0,0,0.05);
            -moz-box-shadow: 1px -7px 19px 2px rgba(0,0,0,0.05);
            box-shadow: 1px -7px 19px 2px rgba(0,0,0,0.05);
            text-align: center;
            @media screen and (min-width: $screen-sm ){
                margin: 2rem 0;
                min-height: 95%;
            }
            .card-header{
                background-color: white;
                border-bottom: none;
                padding: 1.25rem;
                .location{
                    padding: 2rem 0;
                    text-align: center;
                    text-transform: uppercase;
                    background-color: $bleu-fonce;
                    color: white;
                    font-size: 1.5rem;
                    font-weight: 500;
                    letter-spacing: .1rem;
                }
                .title-2{
                    padding: 2rem 0 0 0;
                    font-weight: bold;
                    font-size: 1.3rem;
                }
                .content-svg{
                    width: 60px;
                    height: 60px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    padding: 1rem;
                    margin: 3rem auto .5em auto;
                    // background color en bas de la page
                } // content svg
            }
            .card-body{
                text-align: center;
                .card-subtitle{
                    font-weight: 100;
                    color: $bleu-fonce;
                    text-transform: uppercase;
                    padding-top: 2rem;
                    padding: 0;
                }

                p{
                    text-align: center !important;
                    color: $bleu-fonce;
                    font-weight: 200;
                    padding: 2rem 2rem 1rem 2rem;
                    line-height: 1.3rem;
                    margin-bottom: 0;
                    @media screen and (min-width: $screen-sm ){
                       font-size: 1rem;
                       line-height: 1.3rem;
                    }
        
                }
        
            } // card body
            .card-footer{
                background-color: white;
                border: none;
                .content-svg{
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    padding: 1rem;
                    margin: 1rem auto;
                    svg{
                        width: 100%;
                        height: 100%;
                    }
                }
            } 
        } // card
    }


// Ajouter différentes couleurs

.block-section-cards{
    .container{
        .row{
            .col-12{
                &:nth-child(1){
                    a{
                        color: $vert-fonce;
                        .card{
                            border-bottom: 10px solid $vert-fonce;
                            .title-2{
                                color: $vert-fonce;
                            }
                            .content-svg{
                                background-color: $vert-fonce;
                            }
                        }
                        &:hover{
                            color: darken($color: #4c9562, $amount: 10%);
                            .card{
                                border-bottom: 10px solid darken($color: #4c9562, $amount: 10%);;
                                .title-2{
                                    color: darken($color: #4c9562, $amount: 10%);
                                }
                                .content-svg{
                                    background-color: darken($color: #4c9562, $amount: 10%);;
                                }
                            }
                        }
                    }
                }
                &:nth-child(2){
                    a{
                        color: $violet;
                        .card{
                            border-bottom: 10px solid $violet;
                            .title-2{
                                color: $violet;
                            }
                            .content-svg{
                                background-color: $violet;
                            }
                        }
                        &:hover{
                            color: darken($color: #733972, $amount: 10%);
                            .card{
                                border-bottom: 10px solid darken($color: #733972, $amount: 10%);;
                                .title-2{
                                    color: darken($color: #733972, $amount: 10%);
                                }
                                .content-svg{
                                    background-color: darken($color: #733972, $amount: 10%);;
                                }
                            }
                        }
                    }
                }
                &:nth-child(3){
                    a{
                        color: $bleu-fonce;
                        .card{
                            border-bottom: 10px solid $bleu-fonce;
                            .title-2{
                                color: $bleu-fonce;
                            }
                            .content-svg{
                                background-color: $bleu-fonce;
                            }
                        }
                        &:hover{
                            color: darken($color: #3b5b88, $amount: 10%);
                            .card{
                                border-bottom: 10px solid darken($color: #3b5b88, $amount: 10%);;
                                .title-2{
                                    color: darken($color: #3b5b88, $amount: 10%);
                                }
                                .content-svg{
                                    background-color: darken($color: #3b5b88, $amount: 10%);;
                                }
                            }
                        }
                    }
                }
                &:nth-child(4){
                    a{
                        color: $vert-clair;
                        .card{
                            border-bottom: 10px solid $vert-clair;
                            .title-2{
                                color: $vert-clair;
                            }
                            .content-svg{
                                background-color: $vert-clair;
                            }
                        }
                        &:hover{
                            color: darken($color: #b1c903, $amount: 10%);
                            .card{
                                border-bottom: 10px solid darken($color: #b1c903, $amount: 10%);;
                                .title-2{
                                    color: darken($color: #b1c903, $amount: 10%);
                                }
                                .content-svg{
                                    background-color: darken($color: #b1c903, $amount: 10%);;
                                }
                            }
                        }
                    }
                }
                &:nth-child(5){
                   a{
                    color: $rose;
                        .card{
                            border-bottom: 10px solid $rose;
                            .title-2{
                                color: $rose;
                            }
                            .content-svg{
                                background-color: $rose;
                            }
                            .card-footer{
                                .content-svg{
                                    background-color: $rose;
                                }
                            }
                        }
                        &:hover{
                            color: darken($color: #b9036c, $amount: 10%);
                            .card{
                                border-bottom: 10px solid darken($color: #b9036c, $amount: 10%);;
                                .title-2{
                                    color: darken($color: #b9036c, $amount: 10%);
                                }
                                .content-svg{
                                    background-color: darken($color: #b9036c, $amount: 10%);;
                                }
                            }
                        }
                   }
                }
                &:nth-child(6){
                    a{
                        color: $vert-deau;
                        .card{
                            border-bottom: 10px solid $vert-deau;
                            .title-2{
                                color: $vert-deau;
                            }
                            .content-svg{
                                background-color: $vert-deau;
                            }
                            .card-footer{
                                .content-svg{
                                    background-color: $vert-deau;
                                }
                            }
                        }
                        &:hover{
                            color: darken($color: #22b3b1, $amount: 10%);
                            .card{
                                border-bottom: 10px solid darken($color: #22b3b1, $amount: 10%);;
                                .title-2{
                                    color: darken($color: #22b3b1, $amount: 10%);
                                }
                                .content-svg{
                                    background-color: darken($color: #22b3b1, $amount: 10%);;
                                }
                            }
                        }
                    }
                }
                &:nth-child(7){
                    a{
                        color: $orange;
                        .card{
                            border-bottom: 10px solid $orange;
                            .title-2{
                                color: $orange;
                            }
                            .content-svg{
                                background-color: $orange;
                            }
                        }
                        &:hover{
                            color: darken($color: #f9b200, $amount: 10%);
                            .card{
                                border-bottom: 10px solid darken($color: #f9b200, $amount: 10%);;
                                .title-2{
                                    color: darken($color: #f9b200, $amount: 10%);
                                }
                                .content-svg{
                                    background-color: darken($color: #f9b200, $amount: 10%);;
                                }
                            }
                        }
                    }
                }
                &:nth-child(8){
                    a{
                        color: $bleu-clair;
                        .card{
                            border-bottom: 10px solid $bleu-clair;
                            .title-2{
                                color: $bleu-clair;
                            }
                            .content-svg{
                                background-color: $bleu-clair;
                            }
                        }
                        &:hover{
                            color: darken($color: #559bcf, $amount: 10%);
                            .card{
                                border-bottom: 10px solid darken($color: #559bcf, $amount: 10%);;
                                .title-2{
                                    color: darken($color: #559bcf, $amount: 10%);
                                }
                                .content-svg{
                                    background-color: darken($color: #559bcf, $amount: 10%);;
                                }
                            }
                        }
                    }
                }
            } // col 12
        }
    }
}

}
