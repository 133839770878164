// ---------------------------------------------------------
// HEADER
// ---------------------------------------------------------


header{
    background-color: white;
    position: absolute;
    left: -70%;
    width: 70%;
    height: 100%;
    margin-top: 60px;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;

    @media screen and (min-width: $screen-sm) {
    	display: flex;
	    align-items: center;
	    box-shadow: 0 1px 6px 0 rgba(32,33,36,.28);
	    left: auto;
    	width: 100%;
		top: 0;
        position: sticky;
        margin-top: 0;
		z-index: 999;
    }

    ul{
    	font-size: 1em;
        font-weight: 700;
        
    	@media screen and (min-width: $screen-sm) {
	    	display: flex;
	    	align-items: center;
	    	text-align: center;
	    }
    }
    img{
    	max-width: 100%;
    }

    nav{
    	@media screen and (max-width: $screen-sm) {
	    	display: flex;
	    	flex-direction: column;
	    }
    }

    #primary-nav{
        padding: 1rem;
        li{
        	transition: transform .45s;
        	a{
        		color: $default;
        		font-weight: 500;
        		font-size: .95rem;
        	}
        	&:hover{
				transform: translate(0, -6px);
        	}
        }
    }
    #secondary-nav{
        padding: .5rem;
        li{
        	a{
        		color: $gris-fonce;
        		font-weight: 400;
        		font-size: .85rem;
        		position: relative;
        		margin-left: 1.85rem;
        		&:hover{
					color: $default;
	        	}
        	}
        	&#house-builders{
        		a{
	        		&:before{
	        			width: 19px;
	        			height: 21px;
	        			content: "";
	        			background: url(../fo/img/house-builder.svg) no-repeat;
					    position: absolute;
					    left: -10px;
					    z-index: 9999999999999999;
	        		}
	        	}
        	}
        	&#tenders{
        		a{
	        		&:before{
	        			width: 14px;
	        			height: 21px;
	        			content: "";
	        			background: url(../fo/img/call-for-tenders.svg) no-repeat;
					    position: absolute;
					    left: -10px;
					    z-index: 9999999999999999;
	        		}
	        	}
        	}
        	&#jobs{
        		a{
	        		&:before{
	        			width: 21px;
	        			height: 21px;
	        			content: "";
	        			background: url(../fo/img/job-offers.svg) no-repeat;
					    position: absolute;
					    left: -10px;
					    z-index: 9999999999999999;
	        		}
	        	}
        	}
        	&#research{
        		@media screen and (max-width: $screen-sm) {
	        		margin-left: 30px;
	    			margin-bottom: 1rem;
	    		}
        		#search-form{
        			align-items: center;
        			margin-right: 2.5rem;
        		}
        		input[type="text"] {
				    border-radius: 20px;
				    box-shadow: none;
				    border: 1px solid $borderblue;
				    padding: .5rem 2.5rem .5rem 1rem;
				    background-color: $lightblue;
        		}
        		input[type="submit"]{
        			background: transparent url(../fo/img/search.svg) no-repeat;
        			width: 24px;
        			height: 24px;
        			padding: 0;
        			border-color: transparent;
				    margin-left: -33px;
				    margin-top: -3px;
				    cursor: pointer;
        		}
        	}
        }
    }

    

}