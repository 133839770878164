// ---------------------------------------------------------
// OPERATIONS PAGE
// ---------------------------------------------------------

.content-results{
  padding: 3rem 2rem;
}
.block-main-single-operations{
  .item-content{
    ul{
      @extend p;
      margin-left: 1rem;
      font-style: initial;
      color: $base;
      @media screen and (min-width: $screen-lg ){
        margin-left: 3rem;
      }
      li{
        list-style-type: circle;
        margin-bottom: .25rem;
        color: $base;
      }
    }
  }
}

.block-body-operations{
  @media screen and (min-width: $screen-lg ){
    header{
      position: fixed;
      height: auto;
    }
    .block-main-operations,
    .block-main-terrains{
      margin-top: 115px;
      .map-full-height{
        height: calc(100vh - 115px);
      }
    }
  }
  @media screen and (min-width: $screen-xl){
    .d-none.d-lg-block.col-xl-6{
      position: fixed;
      right: 0;
    }
  }
}